/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
// import Footer from 'components/footer/Footer';
import ProductProvider from 'providers/ProductProvider';
import CourseProvider from 'providers/CourseProvider';
import ModalAuth from 'components/authentication/modal/ModalAuth';
import { useAppContext } from 'providers/AppProvider';
import withAuth from 'components/authentication/withAuth';
import usePubSub from 'pubsub';
import paths, { rootPaths } from 'routes/paths';
import APIService from 'http/api_service';
import useUserStore from 'store/userStore';

const MainLayout = () => {
  const {
    addPubSubEventListener,
    putUserOnlineViaPubSub,
    removePubSubEventListener
  } = usePubSub();
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  // const isChat = pathname.includes('chat');
  const navigate = useNavigate();
  const {
    config: { isFluid, navbarPosition }
  } = useAppContext();
  const setAuthenticatedUser = useUserStore(
    state => state.setAuthenticatedUser
  );

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element?.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
      if (pathname === rootPaths.root) {
        navigate(paths.feed);
      }
    }, 0);
  }, []);

  useEffect(() => {
    const refreshUser = () => {
      APIService.fetchLatestMe((res, err) => {
        if (err) {
          return;
        }
        const { data } = res;
        setAuthenticatedUser(data);
      });
    };
    refreshUser();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )}
      <ProductProvider>
        <CourseProvider>
          <div className={classNames('content', { 'pb-0': isKanban })}>
            <NavbarTop />
            {/*------ Main Routes ------*/}
            <Outlet />
            {/* {!isKanban && <Footer />} */}
          </div>
        </CourseProvider>
      </ProductProvider>
      <ModalAuth />
    </div>
  );
};

export default withAuth(MainLayout);
