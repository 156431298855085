/* eslint-disable react/prop-types */
import { Spinner } from 'react-bootstrap';
import Flex from './Flex';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function FullScreenProgressDialog({ postingFeedBack }) {
  const { t } = useTranslation();

  return (
    <Flex
      alignItems={'center'}
      justifyContent={'center'}
      direction={'column'}
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        borderRadius: 6,
        background: `rgba(0, 0, 0, 0.${postingFeedBack?.progress ? 6 : 1})`
      }}
    >
      {!postingFeedBack?.progress && <Spinner variant="dark" />}
      {postingFeedBack?.progress && (
        <div
          style={{
            width: 50,
            height: 50,
            fontWeight: 'bold'
          }}
        >
          <CircularProgressbar
            value={postingFeedBack?.progress}
            text={`${postingFeedBack?.progress}%`}
            styles={{
              root: {},
              path: {
                stroke: 'blue'
              },
              text: {
                fill: 'white',
                fontSize: '30px',
                fontWeight: 'bold'
              }
            }}
          />
        </div>
      )}
      <span
        style={{
          fontWeight: 600,
          color: postingFeedBack?.progress ? 'white' : 'black'
        }}
      >
        {postingFeedBack?.label != null ? postingFeedBack?.label : t('posting')}
      </span>
    </Flex>
  );
}
