import React from 'react';
import { Col, Row } from 'react-bootstrap';
// import events from 'data/events/events';
import BirthdayNotice from './BirthdatNotice';
import AddToFeed from './AddToFeed';
// import Events from 'components/pages/user/profile/Events';
import FeedContent from './FeedContent';
import FeedProvider from 'providers/FeedProvider';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const Feed = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`Feed - ${t('app_name')}`}</title>
        <meta charSet="utf-8" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@reachableonline " />
        <meta name="twitter:creator" content="@reachableonline " />
        <meta property="og:description" content={t('app_name')} />
        <meta
          content="https://twitter.com/reachableonline"
          property="og:see_also"
        />
        <meta name="twitter:title" content={t('app_name')} />
        <meta name="twitter:description" content="" />
        {/* <meta name="twitter:image" content="https://promoteapp.s3.amazonaws.com/promote-twitter-card.jpeg" /> */}
        <meta name="twitter:image:width" content="1001 " />
        <meta name="twitter:image:height" content="459 " />
        <link href="https://reachable.chat/" rel="canonical " />
        <link href="https://reachable.chat/" rel="home " />
      </Helmet>
      <FeedProvider>
        <Row className="g-0 g-lg-3">
          <Col lg={8}>
            <FeedContent />
          </Col>
          <Col lg={4}>
            <BirthdayNotice
              name="Emma Watson"
              profileLink="/user/profile"
              className="mb-3"
            />
            <AddToFeed />
            {/* <Events cardTitle="You may interested" events={events.slice(2, 5)} /> */}
          </Col>
        </Row>
      </FeedProvider>
    </>
  );
};

export default Feed;
