/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { Card, Button } from 'react-bootstrap';
import corner1 from 'assets/img/illustrations/corner-2.png';
import Background from 'components/common/Background';

const BirthdayNotice = () => {
  return (
    <>
      <Card className="shadow-none border border-200 mb-3">
        <Background image={corner1} className="p-x1 bg-card" />
        <Card.Body className="position-relative p-3 pb-3">
          <h4 className="text-secondary">Upgrade to</h4>
          <h4 className="text-warning">BusinessHunt Premium</h4>
          <p className="fs-10 mb-0">
            Unlimited access to businesses for sale, press mentions, and talent for hire
          </p>
          <Button
            as={Link}
            variant="link"
            className="fs-10 text-warning mt-lg-3 ps-0"
            to="/pricing/pricing-alt"
          >
            Upgrade now
            <FontAwesomeIcon icon="chevron-right" className="ms-1 fs-11" />
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

BirthdayNotice.propTypes = {
  name: PropTypes.string.isRequired,
  profileLink: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default BirthdayNotice;
