/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { Link } from 'react-router-dom';
import FeedDropdown from './FeedDropdown';
import paths from 'routes/paths';
import { getFullName, getUsername } from 'utils/user_utils';
import { formatPostDate } from 'utils/time';
import SubtleBadge from 'components/common/SubtleBadge';
import useUserStore from 'store/userStore';

const FeedCardHeader = ({
  // eslint-disable-next-line react/prop-types
  _id,
  avatar,
  created_at,
  location,
  online = 'online',
  privacy,
  shared,
  topics,
  post,
  ...rest
}) => {
  return (
    <Card.Header className="bg-body-tertiary">
      <Row className="justify-content-between">
        <Col>
          <Flex
            className={'pb-3 ms-0 ps-0 gap-1'}
            style={{
              flexWrap: 'wrap'
            }}
          >
            {topics.map(topic => {
              return (
                <SubtleBadge pill bg={'info'} className="me-2" key={topic}>
                  {topic}
                </SubtleBadge>
              );
            })}
          </Flex>
          <Flex>
            <Link className="d-flex" to={paths.userProfile}>
              <Avatar size="2xl" src={avatar} className={`status-${online}`} />
            </Link>
            <div className="flex-1 align-self-center ms-2">
              <p className="mb-1 lh-1">
                <Link className="fw-semibold" to={`/${getUsername(rest)}`}>
                  {getFullName(rest)}
                </Link>
                {shared && (
                  <span className="ms-1">
                    shared {/^[aeiou]/g.test(shared.toLowerCase()) ? 'an' : 'a'}{' '}
                    <a href="#!">{`${shared}`}</a>
                  </span>
                )}
              </p>
              <p className="mb-0 fs-10">{`${formatPostDate(created_at)}`}</p>
            </div>
          </Flex>
        </Col>
        <Col xs="auto">
          <FeedDropdown authorId={_id} post={post} />
        </Col>
      </Row>
    </Card.Header>
  );
};

export default FeedCardHeader;
