import React, { useState } from 'react';
import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import SubmitButton from 'components/common/SubmitButton';
import SwitchEmail from 'components/authentication/simple/EmailSwitch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useAppContext } from 'providers/AppProvider';
import useUserStore from 'store/userStore';
import { useNavigate } from 'react-router-dom';
import APIService from 'http/api_service';
import { rootPaths } from 'routes/paths';
import { toast } from 'react-toastify';
import { topics } from 'components/app/social/feed/data/topics';

const UpdateInterests = () => {
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);
  const setAuthenticatedUser = useUserStore(
    state => state.setAuthenticatedUser
  );
  const authenticatedUser = useUserStore(state => state.authenticatedUser);
  const [formData, setFormData] = useState([
    ...(authenticatedUser?.interests ?? [])
  ]);
  let interests = topics;
  const {
    config: { isDark }
  } = useAppContext();

  const navigate = useNavigate();

  const handleSubmit = e => {
    e.preventDefault();
    setProcessing(true);
    APIService.updateAccountDetails(
      { interests: formData },
      (response, err) => {
        setProcessing(false);
        if (err) {
          toast.error(err);
          return;
        }
        let { data } = response;
        setAuthenticatedUser(data);
        navigate(rootPaths.root);
      }
    );
  };

  return (
    <>
      <Flex
        justifyContent="between"
        direction={'column'}
        alignItems="center"
        className="mb-2"
      >
        <h5>{t('interests')}</h5>
        <p>{t('pick_your_interests')}</p>
      </Flex>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-4">
          <Flex
            alignItems={'center'}
            justifyContent={'center'}
            className={'gap-1'}
            style={{
              flexWrap: 'wrap'
            }}
          >
            {interests.map(interest => {
              return (
                <Button
                  key={interest}
                  onClick={() => {
                    setFormData((prevData = []) => {
                      const interestsArray = Array.isArray(prevData)
                        ? [...prevData]
                        : [];
                      const index = interestsArray.indexOf(interest);
                      if (index !== -1) {
                        interestsArray.splice(index, 1);
                      } else {
                        interestsArray.push(interest);
                      }
                      return interestsArray;
                    });
                  }}
                  variant={
                    formData?.includes(interest)
                      ? isDark
                        ? 'light'
                        : 'dark'
                      : `outline-${isDark ? 'light' : 'dark'}`
                  }
                  className="rounded-pill me-1 mb-1"
                >
                  <Flex
                    alignItems={'center'}
                    justifyContent={'center'}
                    className={'gap-1'}
                  >
                    <span>{interest}</span>
                    {formData?.includes(interest) && (
                      <FontAwesomeIcon icon={faCheckCircle} />
                    )}
                  </Flex>
                </Button>
              );
            })}
          </Flex>
        </Form.Group>
        <Form.Group className="mb-4">
          <SubmitButton
            className="w-100"
            disabled={(formData ?? []).length < 1}
            processing={processing}
            label={t('next')}
          />
        </Form.Group>
      </Form>
      <SwitchEmail />
    </>
  );
};

export default UpdateInterests;
