export const orders = [
  {
    id: 1,
    product: 'Business Buyer"',
    quantity: 1,
    productKey: 'Z0V20008N',
    specification: ['2.9GHz 6-core 8th-Gen Intel Core i9', '32GB RAM'],
    price: 1345
  },
  {
    id: 2,
    product: 'Apple iMac Pro',
    quantity: 1,
    productKey: '',
    specification: [
      '27-inch with Retina 5K Display',
      '3.0GHz 10-core Intel Xeon W',
      '1TB'
    ],
    price: 2010
  },
  {
    id: 3,
    product: 'Apple iMac Pro',
    quantity: 1,
    productKey: '',
    specification: [
      '27-inch with Retina 5K Display',
      '3.0GHz 10-core Intel Xeon W',
      '1TB'
    ],
    price: 2010
  }
];

export const pricingOption = [
  {
    id: 1,
    name: 'Buyer',
    description:
      'Connect with founders to explore potential business acquisitions',
    yearlyPlan: 299,
    price: [
      {
        period: 'm',
        yearlyPlan: 299
      }
    ],
    state: 'New Jersey',
    zip: '02139',
    features: [
      {
        id: 0,
        // title: 'Get paid to post',
        body: 'View details of all listed startups',
        isActive: true
      },
      {
        id: 1,
        // title: 'Ad revenue split',
        body: 'Filter listings to find your ideal fit',
        isActive: true
      },
      {
        id: 8,
        // title: 'Unlimited profile discovery',
        body: 'Connect directly with sellers ',
        isActive: true
      },
      {
        id: 2,
        // title: 'Direct donations',
        body: 'Deal flow without delays',
        isActive: true
      },
      {
        id: 4,
        // title: 'Create unlimited Posts',
        body: 'View connected financial, customer, and web traffic metrics',
        isActive: true
      },
      {
        id: 6,
        // title: 'Unlimited profile discovery',
        body: 'Sign, and send LOIs and APAs in minutes',
        isActive: true
      },
      {
        id: 7,
        // title: 'Unlimited profile discovery',
        body: 'View verified seller profiles (email, social media, etc)',
        isActive: true
      }
    ],
    paid: true
  },
  {
    id: 2,
    name: 'Press Mentions',
    description:
      'Secure placements in top publications to grow as a thought leader and expand your reach',
    yearlyPlan: 972,
    monthlyPlan: 97,
    state: 'Alaska',
    zip: '99703',
    features: [
      {
        id: 0,
        title: 'Get paid to post',
        body: 'Earn up to $4 for every 1,000 views on your long posts',
        isActive: true
      },
      {
        id: 1,
        title: 'Ad revenue split',
        body: 'Earn a percentage of the ad revenue generated from ads shown on your content. The more views, the higher the earnings',
        isActive: true
      },
      // {
      //   id: 2,
      //   title: 'Direct donations',
      //   body: 'Allow your audience to send you tips or donations',
      //   isActive: true
      // },
      {
        id: 3,
        title: 'Subscriber tiers',
        body: 'Offer exclusive content to subscribers who pay you a monthly fee. Customize your subscription tiers and benefits',
        isActive: true
      },
      {
        id: 4,
        title: 'Create unlimited Posts',
        body: 'Create unlimited Posts with premium AI text and image generation',
        isActive: true
      },
      {
        id: 5,
        title: 'Unlimited profile discovery',
        body: 'Connect for friendship, business partnerships, relationships, and more',
        isActive: true
      }
    ],
    paid: true
  },
  {
    id: 3,
    name: 'Hire Talent',
    description:
      'Connect with founders, business buyers, and hiring managers for potential job opportunities.',
    yearlyPlan: 'Contact us',
    state: 'Alaska',
    zip: '99703',
    features: [
      {
        id: 0,
        title: 'Get paid to post',
        body: 'Earn up to $4 for every 1,000 views on your long posts',
        isActive: true
      },
      {
        id: 1,
        title: 'Ad revenue split',
        body: 'Earn a percentage of the ad revenue generated from ads shown on your content. The more views, the higher the earnings',
        isActive: true
      },
      // {
      //   id: 2,
      //   title: 'Direct donations',
      //   body: 'Allow your audience to send you tips or donations',
      //   isActive: true
      // },
      {
        id: 3,
        title: 'Subscriber tiers',
        body: 'Offer exclusive content to subscribers who pay you a monthly fee. Customize your subscription tiers and benefits',
        isActive: true
      },
      {
        id: 4,
        title: 'Create unlimited Posts',
        body: 'Create unlimited Posts with premium AI text and image generation',
        isActive: true
      },
      {
        id: 5,
        title: 'Unlimited profile discovery',
        body: 'Connect for friendship, business partnerships, relationships, and more',
        isActive: true
      }
    ],
    paid: false
  }
  // {
  //   id: 4,
  //   name: 'Business Seller',
  //   description:
  //     'List and sell your business with expert support and guidance throughout the entire process at no cost',
  //   yearlyPlan: '$299/year',
  //   state: 'Alaska',
  //   zip: '99703',
  //   features: [
  //     {
  //       id: 0,
  //       title: 'Get paid to post',
  //       body: 'Earn up to $4 for every 1,000 views on your long posts',
  //       isActive: true
  //     },
  //     {
  //       id: 1,
  //       title: 'Ad revenue split',
  //       body: 'Earn a percentage of the ad revenue generated from ads shown on your content. The more views, the higher the earnings',
  //       isActive: true
  //     },
  //     // {
  //     //   id: 2,
  //     //   title: 'Direct donations',
  //     //   body: 'Allow your audience to send you tips or donations',
  //     //   isActive: true
  //     // },
  //     {
  //       id: 3,
  //       title: 'Subscriber tiers',
  //       body: 'Offer exclusive content to subscribers who pay you a monthly fee. Customize your subscription tiers and benefits',
  //       isActive: true
  //     },
  //     {
  //       id: 4,
  //       title: 'Create unlimited Posts',
  //       body: 'Create unlimited Posts with premium AI text and image generation',
  //       isActive: true
  //     },
  //     {
  //       id: 5,
  //       title: 'Unlimited profile discovery',
  //       body: 'Connect for friendship, business partnerships, relationships, and more',
  //       isActive: true
  //     }
  //   ],
  //   paid: false
  // }
];
