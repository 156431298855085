/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleLogin } from '@react-oauth/google';
import { useAppContext } from 'providers/AppProvider';

export default function GoogleSignInButton({ label, intent }) {
  const { t } = useTranslation();
  const {
    config: { isDark }
  } = useAppContext();

  const darkColorSchemeStyle = { colorScheme: 'light' };

  return (
    <div style={isDark ? darkColorSchemeStyle : {}}>
      <GoogleLogin
        width={220}
        shape="circle"
        onSuccess={credentialResponse => {
          console.log(credentialResponse);
        }}
        onError={() => {
          console.log('Login Failed');
        }}
        useOneTap
      />
    </div>
  );
}
