import { Strings } from '../constants';

export const isFreeUser = user => {
  let activePlan = user.active_plan;
  return activePlan?.Name === 'Free' || activePlan?.Key === 'Free';
};

export const getLoggedInUserFromLocalStorage = () => {
  let localStorageUserString = localStorage.getItem(Strings.LOGGED_IN_USER);
  if (localStorageUserString) {
    return JSON.parse(localStorageUserString);
  }
  return null;
};

export const canLoggedInUserBeDemo = user => {
  if (!user) {
    return false;
  }
  let demoEmailAddresses = ['test@reachable.chat', 'juoemeka@gmail.com'];
  return demoEmailAddresses.includes(user.email);
};

export const getFullName = user => {
  return `${user.first_name ?? ''} ${user.last_name ?? ''}`;
};

export const getUsername = user => {
  return `${user.username ?? ''}`;
};

export const abbreviateNumber = number => {
  if (isNaN(number)) return 'NaN';
  if (number === 0) return '0';
  if (Math.abs(number) < 1000) {
    return number.toString();
  }
  const absNumber = Math.abs(number);
  const abbreviations = ['', 'k', 'M', 'B', 'T'];
  const tier = (Math.log10(absNumber) / 3) | 0;
  const suffix = abbreviations[tier];
  const scale = Math.pow(10, tier * 3);
  const scaledNumber = number / scale;
  return scaledNumber.toFixed(1) + suffix;
};
