/* eslint-disable react/prop-types */
import React from 'react';
import GoogleSignInButton from './GoogleSignInButton';
import FacebookSignInButton from './FacebookSignInButton';
import Flex from 'components/common/Flex';
const SocialAuthButtons = ({ intent = 'login', loosedSocials }) => {
  return (
    <Flex
      justifyContent={'center'}
      alignItems={'center'}
      className={'gap-2'}
      style={{
        flexWrap: 'wrap'
      }}
    >
      <div style={{ flex: 1 }}>
        <GoogleSignInButton intent={intent} />
      </div>
      <div style={{ flex: 1 }}>
        <FacebookSignInButton intent={intent} loosedSocials={loosedSocials} />
      </div>
    </Flex>
  );
};

export default SocialAuthButtons;
