export default class EndPoints {
  static get API_VERSION() {
    return 'v1';
  }
  static get ROOT_DOMAIN() {
    return 'reachable.chat';
  }
  static get LOCAL_BASE_ROOT() {
    return 'http://localhost:8000';
  }
  static get REMOTE_BASE_ROOT() {
    return `https://api.${this.ROOT_DOMAIN}`;
  }
  static get LOCAL_BASE() {
    return `${this.LOCAL_BASE_ROOT}/${this.API_VERSION}`;
  }
  static get REMOTE_BASE() {
    return `${this.REMOTE_BASE_ROOT}/${this.API_VERSION}`;
  }
  static get BASE() {
    return this.REMOTE_BASE;
  }
  static get SOCKET_BASE() {
    return this.REMOTE_BASE_ROOT;
  }
  static get CSRF_TOKEN() {
    return `${this.BASE}/accounts/csrf_token`;
  }
  static get SIGN_UP() {
    return `${this.BASE}/accounts/signup`;
  }
  static get UPDATE_PERSONAL_INFO() {
    return `${this.BASE}/accounts/update`;
  }
  static get UPLOAD_FILE() {
    return `${this.BASE}/files/upload`;
  }
  static get UPLOADED_FILE_URL() {
    return `${this.BASE}/files/get-url`;
  }
  static get CHECK_IF_EMAIL_IS_VERIFIED() {
    return `${this.BASE}/accounts/check_if_email_is_verified`;
  }
  static get REQUEST_CODE_FOR_EMAIL_VERIFICATION() {
    return `${this.BASE}/accounts/request_code_for_email_verification`;
  }
  static get CONFIRM_CODE_FOR_EMAIL_VERIFICATION() {
    return `${this.BASE}/accounts/confirm_code_for_email_verification`;
  }
  static get UPDATE_ACCOUNT_PASSWORD() {
    return `${this.BASE}/accounts/update_account_password`;
  }
  static get UPDATE_ACCOUNT_DETAILS() {
    return `${this.BASE}/accounts/update_account_details`;
  }
  static get FETCH_ACCOUNT_BY_USERNAME() {
    return `${this.BASE}/accounts/public/:username`;
  }
  static get SIGN_IN() {
    return `${this.BASE}/accounts/login`;
  }
  static get LOG_OUT() {
    return `${this.BASE}/accounts/logout`;
  }
  static get DELETE_ACCOUNT() {
    return `${this.BASE}/accounts/delete_account`;
  }
  static get ME() {
    return `${this.BASE}/accounts/me`;
  }
  static get MY_FINGER_PRINT() {
    return `${this.BASE}/accounts/my_meta_data`;
  }
  static get INITIATE_PASSWORD_RESET() {
    return `${this.BASE}/accounts/initiate_pwd_reset`;
  }
  static get FINALIZE_PASSWORD_RESET() {
    return `${this.BASE}/accounts/reset_pwd`;
  }
  static get CONFIRM_PASSWORD_LESS_CONFIRMATION_CODE() {
    return `${this.BASE}/accounts/confirm_passwordless_confirmation_code`;
  }
  static get FETCH_CONVERSATIONS_OF_PARTICIPANT() {
    return `${this.BASE}/get_conversations_of_participant/:participantId`;
  }
  static get POSTS() {
    return `${this.BASE}/posts`;
  }
  static get COMMENTS() {
    return `${this.BASE}/comments`;
  }
  static get UPSERT_POST() {
    return `${this.POSTS}/upsert_post`;
  }
  static get POSTS_OF_INTEREST() {
    return `${this.POSTS}/of_interest`;
  }
  static get DELETE_POST() {
    return `${this.POSTS}/:id/delete`;
  }
  static get LIKE_OR_UN_LIKE_POST() {
    return `${this.POSTS}/:postId/like_or_unlike`;
  }
  static get LIKE_OR_UN_LIKE_COMMENT() {
    return `${this.POSTS}/:postId/comments/:commentId/like_or_unlike`;
  }
  static get DELETE_COMMENT() {
    return `${this.POSTS}/:postId/comments/:commentId/delete`;
  }
  static get UPSERT_COMMENT() {
    return `${this.POSTS}/upsert_comment`;
  }
}
