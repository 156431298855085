import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import CreatePostForm from './CreatePostForm';
import useUserStore from 'store/userStore';
import { useTranslation } from 'react-i18next';
import FullScreenProgressDialog from 'components/common/FullScreenSpinner';

const CreatePost = ({ ...rest }) => {
  const { t } = useTranslation();
  const authenticatedUser = useUserStore(state => state.authenticatedUser);
  const [postingFeedBack, setPostingFeedBack] = useState();

  const handlePostingFeedBack = feedBack => {
    setPostingFeedBack(feedBack);
  };

  return (
    <Card
      {...rest}
      style={{
        position: 'relative'
      }}
    >
      <Card.Header className="bg-body-tertiary">
        <Flex alignItems="center">
          <Avatar size="m" src={authenticatedUser?.avatar ?? ''} />
          <div className="flex-1 ms-2">
            <h5 className="mb-0 fs-9">{t('create_post')}</h5>
          </div>
        </Flex>
      </Card.Header>
      <Card.Body className="p-0">
        <CreatePostForm
          postingFeedBack={postingFeedBack}
          handlePostingFeedBack={handlePostingFeedBack}
        />
      </Card.Body>
      {postingFeedBack && (
        <FullScreenProgressDialog postingFeedBack={postingFeedBack} />
      )}
    </Card>
  );
};

export default CreatePost;
