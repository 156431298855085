/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import avatar from 'assets/img/team/reachable-avatar.png';
import VerifiedBadge from 'components/common/VerifiedBadge';
import { CloseButton, Col, Form, Button, Modal, Row } from 'react-bootstrap';
import ProfileBanner from '../ProfileBanner';
import useUserStore from 'store/userStore';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import SubmitButton from 'components/common/SubmitButton';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Avatar from 'components/common/Avatar';

const Banner = ({
  isProfileEditModalOpen,
  setIsProfileEditModalOpen,
  closeProfileEditModal
}) => {
  const authenticatedUser = useUserStore(state => state.authenticatedUser);
  const { username } = useParams();
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);
  const [user, setUser] = useState(null);
  console.log('fetchAccountByUsername', user)
  const setAuthenticatedUser = useUserStore(
    state => state.setAuthenticatedUser
  );
  const [formData, setFormData] = useState({
    first_name: authenticatedUser?.first_name,
    last_name: authenticatedUser?.last_name,
    username: authenticatedUser?.username,
    bio: authenticatedUser?.bio
  });
  
  const handleSubmit = e => {
    e.preventDefault();
    setProcessing(true);
    APIService.updateAccountDetails(formData, (response, err) => {
      setProcessing(false);
      if (err) {
        toast.error(err);
        return;
      }
      let { data } = response;
      setAuthenticatedUser(data);
      setIsProfileEditModalOpen(false);
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const fetchAccountByUsername = () => {
    APIService.fetchAccountByUsername(username, (response, error) => {
      if (error) {
        return;
      }
      let { data } = response;
      setUser(data)
    });
  }

  useEffect(() => {
    if (username) {
      fetchAccountByUsername()
    }
  }, [])

  return (
    <>
      <ProfileBanner>
        <ProfileBanner.Body>
          <Row>
            <Col lg={3} className="mb-4">
            <div
                className='position-relative mb-7'
                style={{ minHeight: '10vh' }}
              >
                <Avatar
                  size="5xl"
                  className="avatar-profile"
                  src={user?.avatar ?? avatar}
                  mediaClass="img-thumbnail shadow-sm"
                />
              </div>
            </Col>
            <Col className="ps-2 ps-lg-3">
              <h4 className="mb-1">
                {user?.first_name} {user?.last_name}
                <VerifiedBadge />
              </h4>
              <h6 className="my-3">{user?.username} </h6>
              {(username !== authenticatedUser?.username && username !== "profile") && (
                <Button
                  variant="falcon-primary"
                  size="sm"
                  className="px-3 mt-2 mb-2"
                >
                  Following
                </Button>
              )}
              {(username !== authenticatedUser?.username && username !== "profile") && (
                <Button
                  variant="falcon-default"
                  size="sm"
                    className="px-3 ms-2 mt-2 mb-2"
                >
                  Message
                </Button>
              )}
              {(username === authenticatedUser?.username  || username === "profile")  && (
                <Button
                  variant="falcon-default"
                  onClick={() => setIsProfileEditModalOpen(true)}
                  size="sm"
                  className="px-3 mt-2 mb-2"
                >
                  Edit Profile
                </Button>
              )}
            </Col>
          </Row>
          {/* <Button
          variant="text"
          size="sm"
          className="p-0 m-0 mt-2 mb-2 shadow-none"
        >
          <b>0</b> Following
        </Button>
        <Button
          variant="text"
          as={Link}
          size="sm"
          className="px-3 ms-2 mt-2 mb-2 shadow-none"
        >
          <b>0</b> Followers
        </Button> */}
        </ProfileBanner.Body>
      </ProfileBanner>
      <Modal show={isProfileEditModalOpen} onHide={closeProfileEditModal}>
        <Modal.Header className=" modal-shape-header px-4 position-relative">
          <div className="position-relative z-1" data-bs-theme="light">
            <h4 className="mb-0 text-dark">Edit profile</h4>
          </div>
          <CloseButton
            variant="primary"
            className="position-absolute end-0 me-2 mt-2 top-0"
            onClick={closeProfileEditModal}
          />
        </Modal.Header>
        <Modal.Body className="p-4">
          <Form onSubmit={handleSubmit}>
            <>
              <FloatingLabel
                controlId="floatingInput"
                label="First name"
                className="mb-3"
              >
                <Form.Control
                  placeholder={t('first_name')}
                  value={formData.first_name}
                  name="first_name"
                  onChange={handleFieldChange}
                  type="text"
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingPassword"
                label="Last name"
                className="mb-3"
              >
                <Form.Control
                  placeholder={t('last_name')}
                  value={formData.last_name}
                  name="last_name"
                  onChange={handleFieldChange}
                  type="text"
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingPassword"
                label="Username"
                className="mb-3"
              >
                <Form.Control
                  placeholder={t('username')}
                  value={formData.username}
                  name="username"
                  onChange={handleFieldChange}
                  type="text"
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingPassword"
                label="About me"
                className="mb-3"
              >
                <Form.Control
                  as="textarea"
                  rows={4}
                  style={{ height: '150px' }}
                  placeholder=""
                  value={formData.bio}
                  name="bio"
                  onChange={handleFieldChange}
                />
              </FloatingLabel>
            </>
            <div className="text-end">
              <SubmitButton
                className="w-100"
                disabled={!formData.first_name || !formData.last_name}
                processing={processing}
                label={t('update')}
              />
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Banner;
