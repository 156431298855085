/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React from 'react';
import IconAlert from "components/common/IconAlert";

const DeletedContentBanner = ({ message }) => {
    return (
        <IconAlert variant="info" className="mt-3">
            <span className="fs-10 fw-semibold">{message}</span>
        </IconAlert>
    );
}
export default DeletedContentBanner;