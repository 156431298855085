import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Modal, Nav, Dropdown } from 'react-bootstrap';
import Login from 'components/authentication/simple/Login';
import Registration from 'components/authentication/simple/Registration';
// import ThemeControlDropdown from 'components/navbar/top/ThemeControlDropdown';
import { useTranslation } from 'react-i18next';

const LandingRightSideNavItem = () => {
  const { t } = useTranslation();
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);

  return (
    <Nav navbar className="ms-auto align-items-lg-center">
      <Nav.Item as={'li'} className="me-4 mt-4 mt-lg-0">
        <Nav.Link as={Link} to="/buyer-a-business" className="text-secondary">
          Buyer a business
        </Nav.Link>
      </Nav.Item>
      <Nav.Item as={'li'} className="me-4 mt-4 mt-lg-0">
        <Nav.Link as={Link} to="/seller-a-business" className="text-secondary">
          Seller a business
        </Nav.Link>
      </Nav.Item>
      <Nav.Item as={'li'} className="me-4 mt-4 mt-lg-0">
        <Nav.Link as={Link} to="/hire-talent" className="text-secondary">
          Hire talent
        </Nav.Link>
      </Nav.Item>
      <Nav.Item as={'li'} className="me-4 mt-4 mt-lg-0">
        <Nav.Link as={Link} to="/get-press-mentions" className="text-secondary">
          Get press mentions
        </Nav.Link>
      </Nav.Item>
      <Nav.Item as={'li'} className="me-4 mt-4 mt-lg-0">
        <Nav.Link as={Link} to="/pricing" className="text-secondary">
          Pricing
        </Nav.Link>
      </Nav.Item>
      {/* <Nav.Item as={'li'} className="me-2">
        <ThemeControlDropdown iconClassName="fs-10" />
      </Nav.Item> */}
      <Dropdown>
        <Dropdown.Toggle
          as={Link}
          to="#!"
          className="nav-link fw-semibold text-secondary mt-4 mt-lg-0"
        >
          {t('login')}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-end dropdown-menu-card mt-0 dropdown-caret dropdown-caret-bg">
          <Card className="shadow-none">
            <Card.Body className="fs-10 fw-normal p-4">
              <Login loosedSocials={false} />
            </Card.Body>
          </Card>
        </Dropdown.Menu>
      </Dropdown>
      <Nav.Item>
        <Nav.Link
          id="landing-register-button"
          as={Link}
          to="#!"
          onClick={() => setShowRegistrationModal(!showRegistrationModal)}
        >
          <Button
            as={Link}
            variant="falcon-primary"
            size="lg"
            className="border-2 rounded-pill fs-9 py-2 mt-4 mt-lg-0"
            to="#!"
          >
            {t('register')}
          </Button>
        </Nav.Link>
        <Modal
          show={showRegistrationModal}
          centered
          onHide={() => setShowRegistrationModal(false)}
        >
          <Modal.Body className="p-0">
            <Card>
              <Card.Body className="fs-10 fw-normal p-4">
                <Registration loosedSocials />
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </Nav.Item>
    </Nav>
  );
};

export default LandingRightSideNavItem;
