/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Comment from './Comment';
import APIService from 'http/api_service';

const Comments = ({ commentsCount, ...others }) => {

  const { t } = useTranslation();
  const previableCommentsMaxLength = 2;
  const otherCommentsCount = commentsCount - previableCommentsMaxLength;
  const [comments, setComments] = useState(others.comments.slice(0, previableCommentsMaxLength));

  const toggleCommentLike = ({ postId, commentId, liked }) => {
    let commentsCopy = [...comments];
    let commentIndex = commentsCopy.findIndex(comment => comment._id === commentId);
    if (commentIndex !== -1) {
      let val = !liked;
      let count = commentsCopy[commentIndex].likesCount ?? 1;
      commentsCopy[commentIndex].liked = val;
      commentsCopy[commentIndex].likesCount = val ? count + 1 : count - 1;
      setComments([...commentsCopy]);
    }
    APIService.likeOrUnLikeComment(postId, commentId, (_response, err) => {
      if (err) {
        console.log(err);
        if (commentIndex !== -1) {
          commentsCopy[commentIndex].liked = !liked;
          setComments([...commentsCopy]);
        }
        return;
      }
    });
  }

  const deleteComment = (postId, commentId) => {
    let commentsCopy = [...comments];
    let commentIndex = commentsCopy.findIndex(item => item._id === commentId);
    if (commentIndex !== -1) {
      let comment = commentsCopy[commentIndex]
      commentsCopy[commentIndex] = { ...comment, deleted: true };
      setComments([...commentsCopy]);
      APIService.deleteComment(postId, commentId, (response, err) => {
        if (err) {
          commentsCopy[commentIndex] = { ...comment, deleted: false };
          setComments([...commentsCopy]);
          toast.error(err, { theme: 'colored' });
          return;
        }
      });
    } else {
      toast.error(t('oops', { theme: 'colored' }));
    }
  }

  const onNewReplyUpserted = (replies, reply, index) => {
    let repliesCopy = [...(replies ?? [])];
    let replyIndex = repliesCopy.findIndex((entry) => reply._id === entry._id);
    if (replyIndex !== -1) {
      repliesCopy[replyIndex] = reply;
    } else {
      repliesCopy.unshift(reply);
    }
    let commentsCopy = [...comments];
    let comment = commentsCopy[index];
    if (comment) {
      comment['replies'] = repliesCopy;
      commentsCopy[index] = comment;
      setComments([...commentsCopy]);
    }
  }

  return (
    <>
      {(comments ?? []).map((comment, index) => (
        <Comment
          key={comment._id}
          index={index}
          onNewReplyUpserted={onNewReplyUpserted}
          deleteComment={deleteComment}
          toggleCommentLike={toggleCommentLike}
          {...comment}
          setComments={setComments}
        />
      ))}
      {commentsCount > previableCommentsMaxLength && (
        <span className="fs-10 text-700 d-inline-block mt-2 cursor-pointer">
          {t('load_more_comments')} {otherCommentsCount > 0 && `(${comments.length} of ${commentsCount})`}
        </span>
      )}
    </>
  );
};

export default Comments;
