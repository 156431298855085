/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import ProductDetailsFooter from './ProfileDetailsFooter';
import Banner from './Banner';
import { Helmet } from 'react-helmet';
import APIService from 'http/api_service';
import { useTranslation } from 'react-i18next';
import useUserStore from 'store/userStore';

const UserProfileDetails = () => {
  const { username } = useParams();
  const authenticatedUser = useUserStore(state => state.authenticatedUser);
  const [user, setUser] = useState(null);
  const { t } = useTranslation();

  const [isProfileEditModalOpen, setIsProfileEditModalOpen] = useState(false);

  const openProfileEditModal = () => setIsProfileEditModalOpen(true);
  const closeProfileEditModal = () => setIsProfileEditModalOpen(false);

  const fetchAccountByUsername = () => {
    APIService.fetchAccountByUsername(username, (response, error) => {
      if (error) {
        return;
      }
      let { data } = response;
      setUser(data);
    });
  };

  useEffect(() => {
    if (username) {
      fetchAccountByUsername()
    }
  }, [])


  return (
    <>
      <Helmet>
        <title>
         {`${user?.first_name} ${user?.last_name} - ${t('app_name')}`}
        </title>
        <meta charSet="utf-8" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@reachableonline " />
        <meta name="twitter:creator" content="@reachableonline " />
        <meta property="og:description" content={t('app_name')} />
        <meta
          content="https://twitter.com/reachableonline"
          property="og:see_also"
        />
        <meta name="twitter:title" content={t('app_name')} />
        <meta name="twitter:description" content="" />
        {/* <meta name="twitter:image" content="https://promoteapp.s3.amazonaws.com/promote-twitter-card.jpeg" /> */}
        <meta name="twitter:image:width" content="1001 " />
        <meta name="twitter:image:height" content="459 " />
        <link href="https://reachable.chat/" rel="canonical " />
        <link href="https://reachable.chat/" rel="home " />
      </Helmet>
      <Card className="shadow-none">
        <Card.Body>
          <Row>
            <Col lg={12} className="mb-4 mb-lg-0">
              <Banner
                username={username}
                isProfileEditModalOpen={isProfileEditModalOpen}
                setIsProfileEditModalOpen={setIsProfileEditModalOpen}
                closeProfileEditModal={closeProfileEditModal}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ProductDetailsFooter
                authenticatedUser={authenticatedUser}
                user={user}
                openProfileEditModal={openProfileEditModal}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default UserProfileDetails;
