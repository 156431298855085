/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useAppContext } from 'providers/AppProvider';
import IconButton from 'components/common/IconButton';
// import dashboard from 'assets/img/generic/dashboard-alt.jpg';
import Section from 'components/common/Section';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import { Typewriter } from 'react-simple-typewriter';
import { useTranslation } from 'react-i18next';
import useUserStore from 'store/userStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import HeroCard from './HeroCard';
import HowItWorks from './HowItWorks';
import BusinessesForSale from 'components/pages/landing/BusinessesForSale';
import { intelligence } from 'data/dashboard/analytics';
import Testimonial from './Testimonial';
import { courseFeatures } from 'data/elearning/courseDetails';

const Hero = () => {
  const {
    config: { isDark, isRTL }
  } = useAppContext();
  const { t } = useTranslation();
  const authenticatedUser = useUserStore(state => state.authenticatedUser);

  return (
    <Section className="py-0 overflow-hidden" position="left bottom" overlay>
      <Row className="mb-6 justify-content-center text-center align-items-center pt-8 pt-lg-8 pb-xl-0">
        <Col lg={10}>
          {/* <h1 className="fw-bold fs-4 fs-sm-4 fs-md-8">
              The only AI{" "}
              <span style={{ color: "#00d27a" }}>customer service solution</span>{" "}
              you need
            </h1>  */}
          <h1 className="fw-bold fs-4 fs-lg-3">
            <span style={{ color: '#00d27a' }}>Marketplace </span> to buy & sell startups, hire talent, and get press mentions ✨
          </h1>
          <p className="lead mt-4">
            Join thousands of satisfied customers who have bought and sold
            hundreds of businesses, hired skilled talent affordably, and
            received press recognition.
          </p>

          {authenticatedUser ? (
            <Link
              className="btn btn-primary border-2x btn-lg mb-3 fs-1"
              to="/dashboard"
            >
              GO TO DASHBOARD
            </Link>
          ) : (
            <>
              <Row className="g-3 my-4 justify-content-center mx-auto">
                <Col lg={3}>
                  <Button
                    variant="falcon-default"
                    className="d-block w-100 mb-2 mb-xl-0 rounded-pill"
                    tag={Link}
                    to="/pricing"
                  >
                    {/* <img src={gmail} width="20" alt="icon" /> */}
                    <span className="fw-medium">View busine for sale</span>
                  </Button>
                </Col>
                <Col lg={3}>
                  <IconButton
                    className="d-block w-100 mb-2 mb-xl-0 rounded-pill"
                    // iconClassName="me-2"
                    tag={Link}
                    to="/pricing"
                    variant="falcon-default"
                    // onClick={handleCopy}
                  >
                    Sell a business
                  </IconButton>
                </Col>
                <Col lg={3}>
                  <IconButton
                    className="d-block w-100 mb-xl-0 rounded-pill"
                    variant="falcon-default"
                    tag={Link}
                    to="/pricing"
                    // size="lg"
                    // icon={['fab', 'facebook-square']}
                    // iconClassName="text-facebook me-2"
                    transform="grow-2"
                  >
                    Get press mention
                  </IconButton>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <br />
      <Testimonial data={courseFeatures} />
      <br />
      <br />
      <br />
      <HowItWorks />
      <BusinessesForSale />
      <br />
      <br />
      <br />
      <br />
      <Row className=" mb-6 justify-content-left align-items-left pt-7 pt-lg-8 pb-lg-9 pb-xl-0">
        <Col md={11} lg={8} xl={6} className="pt-8  text-left text-xl-left">
          <h1 className="mb-3 font-weight-bold fs-lg-5">
            Marketplace for{' '}
            <span style={{ color: '#00d27a' }}>buying/selling startups</span>
            and <span style={{ color: '#00d27a' }}>hiring skilled talent</span>
          </h1>
          <p className="mb-3 lead text-dark opacity-75">
            Join satisfied customers who bought/sold hundreds of businesses and
            hired affordable skilled talent{' '}
            {/* <span>
              <Typewriter
                words={['Africa', 'North America', 'Nigeria']}
                loop={false}
                cursor={!isRTL ? true : false}
                cursorStyle="|"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span> */}
          </p>
          {authenticatedUser ? (
            <>
              <Flex
                alignItems="center"
                className="px-3"
                style={{
                  flexWrap: 'wrap'
                }}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip style={{ position: 'fixed' }}>Pass</Tooltip>
                  }
                >
                  <Button variant="falcon-default" size="lg" className="me-2">
                    <FontAwesomeIcon className="text-danger" icon="xmark" />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip style={{ position: 'fixed' }}>Connect</Tooltip>
                  }
                >
                  <Button
                    variant="falcon-default"
                    size="lg"
                    // onClick={handleFavouriteClick}
                  >
                    <FontAwesomeIcon
                      className="text-success"
                      // icon={isInFavouriteItems(id) ? 'heart' : ['far', 'heart']}
                    />
                  </Button>
                </OverlayTrigger>
              </Flex>
              <Row className="mt-4" form>
                <Link
                  className="btn btn-primary border-2x btn-lg mb-3 fs-0 mr-2"
                  to="/dashboard/swap"
                >
                  Go to dashboard
                </Link>
                <a
                  className="btn btn-outline-dark border-2x btn-lg mb-3 fs-0 mr-2"
                  href="https://calendly.com/emekajohnson"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Explore templates
                </a>
              </Row>
            </>
          ) : (
            <>
              <Flex alignItems="center">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip style={{ position: 'fixed' }}>Pass</Tooltip>
                  }
                >
                  <Button variant="primary" size="lg" className="me-2">
                    View businesses for sale
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip style={{ position: 'fixed' }}>Connect</Tooltip>
                  }
                >
                  <Button
                    variant="falcon-default"
                    size="lg"
                    // onClick={handleFavouriteClick}
                  >
                    Hire talent
                  </Button>
                </OverlayTrigger>
              </Flex>
            </>
          )}
        </Col>
        <Col xl={{ size: 6 }} className="align-self-end">
          <HeroCard data={intelligence} />
        </Col>
      </Row>
    </Section>
  );
};

export default Hero;
