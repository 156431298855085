/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { pricingOption } from 'data/ecommerce/checkoutData';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const SubscriptionPlan = () => {
  const [selectedOption, setSelectedOption] = useState(pricingOption[0].id);

  const handleSelect = id => {
    setSelectedOption(id);
  };

  return (
    <>
      <Row className="g-2 mt-6">
        {pricingOption.map(item => (
          <Col key={item.id} md={4} className="mb-3 mb-md-0">
            <Form.Check
              type="radio"
              id={`address-${item.id}`}
              className="mb-0 form-check radio-select"
            >
              <Form.Check.Input
                type="radio"
                name="clientAddress"
                checked={selectedOption === item.id}
                onChange={() => handleSelect(item.id)}
              />
              <Form.Check.Label className="mb-0 fw-bold d-block">
                {item.name}
                <span className="radio-select-content">
                  <span className="mb-4">{item.description}</span>
                  <p className="mt-2 fs-8">
                    {item.paid === true ? <>${item.yearlyPlan}<span className="fs-11 mt-1">/ year</span></> : `Contact us`}
                  </p>
                  {/* {selectedOption === item.id && ( */}
                    <>
                      <ul className="list-unstyled">
                        {item.features.map(feature => (
                          <li
                            key={feature.id}
                            className={classNames('border-bottom py-2', {
                              'text-300': !feature.isActive
                            })}
                          >
                            <FontAwesomeIcon
                              icon="check"
                              className={classNames('text-primary me-1', {
                                'text-300': !feature.isActive
                              })}
                              transform="shrink-2"
                            />
                            {feature?.title && (
                              <>
                                <span style={{ fontWeight: '900' }}>
                                  {feature?.title}
                                </span>
                                <br />
                              </>
                            )}
                            {feature.body}
                          </li>
                        ))}
                      </ul>

                      <Row className="g-2">
                        {item.monthlyPlan && (
                          <Col lg={item.monthlyPlan ? 6 : 12}>
                            <Button
                              variant="primary"
                              className="p-2"
                              tag={Link}
                              to="/pricing"
                            >
                              {item.paid === true ?
                                <>
                                  Pay ${item.monthlyPlan}<span className="fs-11">/mo</span>
                                </>
                                : `${item.monthlyPlan}`}
                             
                            </Button>
                          </Col>
                        )}
                        <Col lg={item.monthlyPlan ? 6 : 12}>
                          <Button
                            className="d-block w-100 p-2"
                            variant="primary"
                            tag={Link}
                            to="/pricing"
                            transform="grow-2"
                          >
                            {item.paid === true ? <>Pay ${item.yearlyPlan}<span className="fs-11 mt-1">/ yr</span></> : `${item.yearlyPlan}`}
                            
                          </Button>
                        </Col>
                      </Row>
                    </>
                  {/* )} */}
                </span>
              </Form.Check.Label>
            </Form.Check>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default SubscriptionPlan;
