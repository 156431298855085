import React from 'react';
import Flex from 'components/common/Flex';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FacebookIcon from 'assets/img/nav-icons/facebook.png';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prop-types
export default function FacebookSignInButton({ label, loosedSocials }) {
  const { t } = useTranslation();

  return (
    <Button
      style={{
        border: '.7px solid #dadce0',
        width: loosedSocials ? 'auto' : 220,
        background: 'white'
      }}
      variant="falcon-light"
      className="rounded-pill"
    >
      <Flex
        alignItems={'center'}
        justifyContent={'center'}
        className={'gap-2 py-1'}
      >
        <img src={FacebookIcon} width={20} height={20} />
        <span
          style={{
            fontSize: 13,
            letterSpacing: '0.25px'
          }}
        >
          {label ?? t('sign_in_with_facebook')}
        </span>
      </Flex>
    </Button>
  );
}
FacebookSignInButton.propTypes = {
  label: PropTypes.string
};
