/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React from 'react';
import FeedCardHeader from './FeedCardHeader';
import { Card } from 'react-bootstrap';
import FeedCardContent from './FeedCardContent';
import FeedCardFooter from './FeedCardFooter';

const FeedCard = ({ feed, ...rest }) => {
  const post = JSON.parse(JSON.stringify(feed));
  const {
    _id,
    created_at,
    topics,
    author,
    text,
    media,
    likesCount,
    commentsCount,
    liked,
    commented,
    likes,
    privacy,
    comments
  } = feed;
  return (
    <Card {...rest}>
      {author && (
        <FeedCardHeader {...{ ...author, created_at, privacy, topics, post }} />
      )}
      {(text || media) && <FeedCardContent {...{ text, media }} />}
      <FeedCardFooter _id={_id} {...{ likesCount, commentsCount, comments, liked, likes, commented }} />
    </Card>
  );
};
export default FeedCard;
