import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DepositeStatus from './DepositeStatus';
import StatisticsCards from './stats-cards/StatisticsCards';
import { files } from 'data/dashboard/default';
import SharedFiles from '../default/SharedFiles';
import PostsPublished from 'components/dashboards/saas/PostsPublished';
// import PostsDraft from 'components/dashboards/saas/PostsDraft';
import BandwidthSaved from '../default/BandwidthSaved';
import DoMoreCard from './DoMoreCard';

const Saas = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col xxl={9}>
          <DepositeStatus />
          <StatisticsCards />
        </Col>
        <Col md={6} xxl={12}>
          <PostsPublished files={files} className="h-100 h-xxl-auto mt-xxl-3" />
        </Col>
        {/* <Col md={6} xxl={12}>
          <PostsDraft files={files} className="h-100 h-xxl-auto mt-xxl-3" />
        </Col> */}
        <Col xxl={3}>
          <Row className="g-3">
            <Col xxl={12}>
              <SharedFiles
                files={files}
                className="h-100 h-xxl-auto mt-xxl-3"
              />
            </Col>
            <Col md={6} xxl={12}>
              <BandwidthSaved />
            </Col>
            <Col md={6} xxl={12}>
              <DoMoreCard />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Saas;
