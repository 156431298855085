import freeIcon from 'assets/img/icons/free.svg';
import proIcon from 'assets/img/icons/pro.svg';

export const pricingData = [
  {
    id: 0,
    title: 'Premium Business Buyer',
    subTitle: 'For teams that need to create project plans with confidence.',
    price: 0,
    url: '/e-commerce/billing',
    buttonText: 'Start free trial',
    isFeatured: false,
    featureTitle: 'Track team projects with free:',
    features: [
      { id: 1, title: 'Timeline' },
      { id: 2, title: 'Advanced Search' },
      { id: 3, title: 'Custom fields', tag: { label: 'New', type: 'success' } },
      { id: 4, title: 'Task dependencies' },
      { id: 5, title: 'Private teams & projects' }
    ]
  },
  {
    id: 1,
    title: 'Premium Business Seller',
    subTitle:
      'For teams and companies that need to manage work across initiatives.',
    price: 39,
    url: '/e-commerce/billing',
    buttonText: 'Get Business',
    isFeatured: true,
    featureTitle: 'Everything in Premium, plus:',
    features: [
      { id: 1, title: 'Portfolios' },
      { id: 2, title: 'Lock custom fields' },
      { id: 3, title: 'Onboarding plan' },
      { id: 4, title: 'Resource Management' },
      { id: 5, title: 'Lock custom fields' }
    ]
  },
  {
    id: 2,
    title: ' Press Mention Premium',
    subTitle: 'For organizations that need additional security and support.',
    price: 99,
    url: '/e-commerce/billing',
    buttonText: 'Purchase',
    isFeatured: false,
    featureTitle: 'Everything in Business, plus:',
    features: [
      { id: 1, title: 'Portfolios' },
      { id: 2, title: 'Tags', tag: { label: 'Coming soon', type: 'primary' } },
      { id: 3, title: 'Onboarding plan' },
      { id: 4, title: 'Resource Management' }
    ]
  }
];

export const pricingAltData = [
  {
    id: 0,
    title: 'Business Buyer',
    subTitle:
      'Connect with founders to explore potential business acquisitions. Sign, and send LOIs and APAs in minutes',
    price: {
      monthly: 49,
      yearly: 39
    },
    period: 'm',
    icon: freeIcon,
    url: '#!',
    buttonText: 'This is your active subscription',
    isFeatured: false,
    featureTitle: '',
    features: [
      {
        id: 0,
        body: 'Limited profile discovery. Connect for friendship, business partnerships, relationships, and more.',
        isActive: true
      },
      {
        id: 1,
        body: 'Use advanced filters to refine your search',
        isActive: true
      },
      {
        id: 2,
        body: 'Control your visibility to appear only when you choose',
        isActive: true
      },
      {
        id: 3,
        body: 'Send unlimited direct messages. Stand out with personalized greetings.',
        isActive: true
      },
      {
        id: 4,
        body: 'Write long articles and send email newsletters',
        isActive: true
      }
    ]
  },

  {
    id: 1,
    title: 'Press Mention',
    subTitle:
      'Secure placements in top publications to grow as a thought leader, and expand your organic reach',
    price: {
      monthly: 49,
      yearly: 39
    },
    period: 'm',
    icon: proIcon,
    url: '#!',
    buttonText: 'Upgrade to premium',
    isFeatured: true,
    featureTitle: 'Everything in Free, plus:',
    features: [
      {
        id: 0,
        title: 'Get paid to post',
        body: 'Earn up to $4 for every 1,000 views on your long posts',
        isActive: true
      },
      {
        id: 1,
        title: 'Ad revenue split',
        body: 'Earn a percentage of the ad revenue generated from ads shown on your content. The more views, the higher the earnings',
        isActive: true
      },
      // {
      //   id: 2,
      //   title: 'Direct donations',
      //   body: 'Allow your audience to send you tips or donations',
      //   isActive: true
      // },
      {
        id: 3,
        title: 'Subscriber tiers',
        body: 'Offer exclusive content to subscribers who pay you a monthly fee. Customize your subscription tiers and benefits',
        isActive: true
      },
      {
        id: 4,
        title: 'Create unlimited Posts',
        body: 'Create unlimited Posts with premium AI text and image generation',
        isActive: true
      },
      {
        id: 5,
        title: 'Unlimited profile discovery',
        body: 'Connect for friendship, business partnerships, relationships, and more',
        isActive: true
      }
    ]
  },

  {
    id: 1,
    title: 'Business Seller',
    subTitle:
      'List and sell your business with expert support and guidance throughout the entire process',
    price: {
      monthly: 0,
      yearly: 0
    },
    period: 'm',
    icon: proIcon,
    url: '#!',
    buttonText: 'Upgrade to premium',
    isFeatured: true,
    featureTitle: 'Everything in Free, plus:',
    features: [
      {
        id: 0,
        title: 'Get paid to post',
        body: 'Earn up to $4 for every 1,000 views on your long posts',
        isActive: true
      },
      {
        id: 1,
        title: 'Ad revenue split',
        body: 'Earn a percentage of the ad revenue generated from ads shown on your content. The more views, the higher the earnings',
        isActive: true
      },
      // {
      //   id: 2,
      //   title: 'Direct donations',
      //   body: 'Allow your audience to send you tips or donations',
      //   isActive: true
      // },
      {
        id: 3,
        title: 'Subscriber tiers',
        body: 'Offer exclusive content to subscribers who pay you a monthly fee. Customize your subscription tiers and benefits',
        isActive: true
      },
      {
        id: 4,
        title: 'Create unlimited Posts',
        body: 'Create unlimited Posts with premium AI text and image generation',
        isActive: true
      },
      {
        id: 5,
        title: 'Unlimited profile discovery',
        body: 'Connect for friendship, business partnerships, relationships, and more',
        isActive: true
      }
    ]
  },
  {
    id: 1,
    title: 'Premium Career',
    subTitle:
      'Connect with founders, business buyers, and hiring managers for potential job opportunities.',
    price: {
      monthly: 19,
      yearly: 15
    },
    period: 'm',
    icon: proIcon,
    url: '#!',
    buttonText: 'Upgrade to premium',
    isFeatured: true,
    featureTitle: 'Everything in Free, plus:',
    features: [
      {
        id: 0,
        title: 'Get paid to post',
        body: 'Earn up to $4 for every 1,000 views on your long posts',
        isActive: true
      },
      {
        id: 1,
        title: 'Ad revenue split',
        body: 'Earn a percentage of the ad revenue generated from ads shown on your content. The more views, the higher the earnings',
        isActive: true
      },
      // {
      //   id: 2,
      //   title: 'Direct donations',
      //   body: 'Allow your audience to send you tips or donations',
      //   isActive: true
      // },
      {
        id: 3,
        title: 'Subscriber tiers',
        body: 'Offer exclusive content to subscribers who pay you a monthly fee. Customize your subscription tiers and benefits',
        isActive: true
      },
      {
        id: 4,
        title: 'Create unlimited Posts',
        body: 'Create unlimited Posts with premium AI text and image generation',
        isActive: true
      },
      {
        id: 5,
        title: 'Unlimited profile discovery',
        body: 'Connect for friendship, business partnerships, relationships, and more',
        isActive: true
      }
    ]
  }
];
