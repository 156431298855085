import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { useProductContext } from 'providers/ProductProvider';
import useProductHook from './usePeopleHook';
import ProductImage from './PeopleImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ProductGrid = ({ product, ...rest }) => {
  const { name, id, isInStock, isNew, files } = product;

  const { isInFavouriteItems } = useProductContext();

  const { handleAddToCart, handleFavouriteClick } = useProductHook(product);
  return (
    <Col className="mb-4" {...rest}>
      <Flex
        direction="column"
        justifyContent="between"
        className="border rounded-1 h-100 pb-3"
      >
        <div
          className="overflow-hidden cursor-pointer"
          onClick={() => handleAddToCart(1, true, true)}
        >
          <ProductImage
            name={name}
            id={id}
            isNew={isNew}
            files={files}
            layout="grid"
          />
          <div className="p-3">
            <Row className="flex-between-center g-2">
              <Col
                sm="auto"
                as={Flex}
                alignItems="center"
                className="mb-2 mb-sm-0"
              >
                <h5
                  className="fs-9 text-1100 cursor-pointer"
                  onClick={() => handleAddToCart(1, true, true)}
                >
                  Emeka Reachable
                </h5>
              </Col>
              <Col sm="auto">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip style={{ position: 'fixed' }}>
                      View profile
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    onClick={() => handleAddToCart(1, true, true)}
                    className="cursor-pointer"
                    icon="circle-info"
                  />
                </OverlayTrigger>
              </Col>
            </Row>
            <p className="fs-10 mb-0">
              <Link to="#!" className="text-500">
                <FontAwesomeIcon
                  className="cursor-pointer"
                  icon="brief-case"
                  // icon={['far', 'briefcase']}
                />
                Customer Service
              </Link>
            </p>
            <p className="fs-10 mb-3">
              <Link to="#!" className="text-500">
                <FontAwesomeIcon className="cursor-pointer" icon="user" /> Man
              </Link>
            </p>
            <h5 className="fs-md-10 text-warning mb-0 d-flex align-items-center mb-3">
              <FontAwesomeIcon
                className="cursor-pointer me-1"
                icon="location-dot"
              />
              6 miles away
            </h5>
            <p className="fs-10 mb-1">
              Looking for:{' '}
              <strong
                className={classNames({
                  'text-success': isInStock,
                  'text-danger': !isInStock
                })}
              >
                {isInStock ? 'Relationship' : 'Sold-Out'}
              </strong>
            </p>
          </div>
        </div>
        <Flex alignItems="center" className="px-3">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip style={{ position: 'fixed' }}>Pass</Tooltip>}
          >
            <Button variant="falcon-default" size="lg" className="me-2">
              <FontAwesomeIcon className="text-danger" icon="xmark" />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip style={{ position: 'fixed' }}>Connect</Tooltip>}
          >
            <Button
              variant="falcon-default"
              size="lg"
              onClick={handleFavouriteClick}
            >
              <FontAwesomeIcon
                className="text-success"
                icon={isInFavouriteItems(id) ? 'heart' : ['far', 'heart']}
              />
            </Button>
          </OverlayTrigger>
        </Flex>
      </Flex>
    </Col>
  );
};

ProductGrid.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    features: PropTypes.array,
    price: PropTypes.number.isRequired,
    discount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    salePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    shippingCost: PropTypes.number,
    rating: PropTypes.number,
    totalReview: PropTypes.number,
    isInStock: PropTypes.bool,
    isNew: PropTypes.bool,
    files: PropTypes.arrayOf(PropTypes.object).isRequired
  })
};

export default ProductGrid;
