import React from 'react';
import { Card, Col, Modal, Row, Button } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { useProductContext } from 'providers/ProductProvider';
import { useAppContext } from 'providers/AppProvider';
import paths from 'routes/paths';
import ProductDetailsFooter from 'components/app/user-profile/profile-details/ProfileDetailsFooter';
import Banner from 'components/app/user-profile/profile-details/Banner';

const CartModal = () => {
  const {
    productsState: {
      cartModal: { show, type }
    },
    productsDispatch
  } = useProductContext();

  const {
    config: { isDark }
  } = useAppContext();

  const handleClose = () => {
    productsDispatch({
      type: 'TOGGLE_CART_MODAL'
    });
  };

  const openUserProfileModal = username => {
    let location = window.location;
    let postDetailsPath = `${location.origin}/${username}`;
    window.history.pushState('', '', postDetailsPath);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header
        closeButton
        closeVariant={isDark ? 'white' : null}
        className="border-200"
      >
        <Modal.Title as="h5">
          <Flex alignItems="center">
            <div className="ms-2">Profile</div>
          </Flex>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="shadow-none">
          <Card.Body>
            <Row>
              <Col lg={12} className="mb-4 mb-lg-0">
                <Banner />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <ProductDetailsFooter />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Modal.Body>
      {type !== 'remove' && (
        <Modal.Footer className="border-200">
          <Button
            to={paths.checkout}
            as={Link}
            size="xs"
            onClick={() => {
              openUserProfileModal('username'); // Replace 'username' with the actual username
              handleClose();
            }}
            variant="secondary"
          >
            Pass
          </Button>
          <IconButton
            as={Link}
            to={paths.shoppingCart}
            size="xs"
            className="ms-2"
            icon="user"
            variant="primary"
            iconAlign="left"
            onClick={handleClose}
          >
            Connect
          </IconButton>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default CartModal;
