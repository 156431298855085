/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import { Card, Row } from 'react-bootstrap';
import PricingAltHeader from './PricingAltHeader';
// import { Link } from 'react-router-dom';
import FaqAlt from 'components/pages/faq/faq-alt/FaqAlt';
import SubscriptionPlans from '../SubscriptionPlans';
import React, { useEffect, useState } from 'react';
import { useAppContext } from 'providers/AppProvider';

const PricingAlt = () => {
  const {
    config: { isFluid, isNavbarVerticalCollapsed },
    setConfig
  } = useAppContext();
  const [pricingPageIsFluid] = useState(isFluid);
  const [pricingPageIsNavbarVerticalCollapsed] = useState(isNavbarVerticalCollapsed);

  useEffect(() => {
    setConfig('isFluid', true);
    setConfig('isNavbarVerticalCollapsed', true);

    return () => {
      setConfig('isFluid', pricingPageIsFluid);
      setConfig('isNavbarVerticalCollapsed', pricingPageIsNavbarVerticalCollapsed);
    };
  }, []);

  return (
    <>
      <Card className="border border-200 mb-3 shadow-none">
        <Card.Body>
          <Row className="justify-content-center">
            <PricingAltHeader />
            <br />
            <br />
            <SubscriptionPlans />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            {/* <Col xs={12} className="text-center">
              <h5 className="mt-5">
                Looking for personal or small team task management?
              </h5>
              <p className="fs-8">
                Try the <Link to="#!">basic version</Link> of Falcon
              </p>
            </Col> */}
          </Row>
        </Card.Body>
      </Card>
      {/* <FaqAlt /> */}
    </>
  );
};

export default PricingAlt;
