/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import commentActive from 'assets/img/illustrations/comment-active.png';
import shareActive from 'assets/img/illustrations/share-active.png';
import shareInactive from 'assets/img/illustrations/share-inactive.png';
import commentInActive from 'assets/img/illustrations/comment-inactive.png';
import { useTranslation } from 'react-i18next';
import usePostsStore from 'store/posts_store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as UnLikedIcon } from '@fortawesome/free-regular-svg-icons';
import { faHeart as LikedIcon } from '@fortawesome/free-solid-svg-icons';
import APIService from 'http/api_service';

const FeedActionButtons = ({ _id, ...others }) => {
  const { t } = useTranslation();
  const liked = others.liked;
  const posts = usePostsStore(state => state.posts);
  const setPosts = usePostsStore(state => state.setPosts);

  const toggleLiked = () => {
    const postsCopy = [...posts];
    let postIndex = postsCopy.findIndex(post => post._id === _id);
    if (postIndex !== -1) {
      let val = !liked;
      let count = postsCopy[postIndex].likesCount ?? 1;
      postsCopy[postIndex].liked = val;
      postsCopy[postIndex].likesCount = val ? count + 1 : count - 1;
      setPosts([...postsCopy]);
    }
    APIService.likeOrUnLikePost(_id, (response, err) => {
      if (err) {
        console.log(err);
        if (postIndex !== -1) {
          postsCopy[postIndex].liked = !liked;
          setPosts([...postsCopy]);
        }
        return;
      }
      let { data } = response;
      let updatedPostsCopy = [...posts];
      let updatedPostIndex = updatedPostsCopy.findIndex(post => post._id === _id);
      if (updatedPostIndex !== -1) {
        updatedPostsCopy[updatedPostIndex] = data;
        setPosts([...updatedPostsCopy]);
      }
    });
  };

  return (
    <Row className="g-0 fw-semibold text-center py-2 fs-10">
      <Col xs="auto">
        <Flex
          alignItems="center"
          className="rounded text-700 me-3 cursor-pointer"
          onClick={toggleLiked}
        >
          <FontAwesomeIcon
            key={`${_id}-like`}
            icon={liked ? LikedIcon : UnLikedIcon}
            style={{
              width: 20,
              height: 20
            }}
            className={liked ? 'text-danger' : ''}
          />
          <span className={`ms-1 ${liked ? 'text-danger' : ''}`}>
            {liked ? t('unlike') : t('like')}
          </span>
        </Flex>
      </Col>
      <Col xs="auto">
        <OverlayTrigger overlay={others?.commented ? <Tooltip>
          {t('you_commented_on_this_post')}
        </Tooltip> : <div></div>}>
          <span>
            <Flex
              alignItems="center"
              className="rounded text-700 me-3 cursor-pointer"
              onClick={() => {
                const commentBoxId = `${_id}-comment-box`;
                document.getElementById(commentBoxId).focus();
              }}
            >
              <img
                src={others?.commented ? commentActive : commentInActive}
                alt=""
                width="20"
              />
              <span className="ms-1">{t('comment')}</span>
            </Flex>
          </span>
        </OverlayTrigger>
      </Col>
      <Col xs="auto">
        <Flex
          alignItems="center"
          className="rounded text-700 me-3 cursor-pointer"
        >
          <img
            src={others?.shared ? shareActive : shareInactive}
            alt=""
            width="20"
          />
          <span className="ms-1">{t('share')}</span>
        </Flex>
      </Col>
    </Row>
  );
};

export default FeedActionButtons;
