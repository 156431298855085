import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import BusinessesForSaleGrid from 'components/pages/landing/BusinessesForSaleGrid';

const BusinessesForSale = () => {
  const staticCourses = [
    { id: 1, name: 'Course 1', price: 100, rating: 4.5, review: 20 },
    { id: 2, name: 'Course 2', price: 200, rating: 4.0, review: 15 },
    { id: 3, name: 'Course 3', price: 150, rating: 4.8, review: 30 },
    { id: 4, name: 'Course 3', price: 150, rating: 4.8, review: 30 }
  ];
  return (
    <Card className="shadow-none">
      <h2 className="text-dark fs-3 font-weight-bold mt-4">
        Popular Startups For Sale
      </h2>
      <Row className="g-3">
        {staticCourses.map(course => (
          <Col md={6} xxl={4} key={course.id}>
            <BusinessesForSaleGrid course={course} />
          </Col>
        ))}
      </Row>
    </Card>
  );
};

export default BusinessesForSale;
