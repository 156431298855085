import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Background from 'components/common/Background';
import corner1 from 'assets/img/illustrations/corner-1.png';
import corner3 from 'assets/img/illustrations/corner-2.png';
// import corner4 from 'assets/img/illustrations/corner-4.png';

const HowItWorks = () => {
  // const [showRegistrationModal, setShowRegistrationModal] = useState(false);

  return (
    <div className="rounded  h-100 d">
      <Row className="g-3">
        <h1
          className="text-dark font-weight-bold mb-4"
          style={{ fontSize: '32px' }}
        >
          How Business Hunt can help you
        </h1>
        <div className=" d-md-block col-md-4 p-2">
          <div className="row">
            <div className="col">
              <Card className="mb-3 shadow-none border">
                <Background image={corner1} className="p-card bg-card" />
                <CardBody className="position-relative">
                  <h4 className="text-warning">Buy business</h4>
                  <p
                    className="text-700 fs-10 fs-sm-10"
                    style={{ fontSize: '16px' }}
                  >
                    Buy your dream business in 30 days. Vetted deals. No hassle.
                  </p>
                  <Link
                    className="font-weight-semi-bold fs-0 text-nowrap"
                    to="/register"
                  >
                    <FontAwesomeIcon
                      icon="video-camera"
                      transform="down-1.5"
                      className="mr-1"
                    />
                    View businesses for sale
                  </Link>
                </CardBody>
              </Card>
              <Card className="mb-3 shadow-none border">
                <Background image={corner3} className="p-card bg-card" />
                <CardBody className="position-relative">
                  <h4 className="text-warning">Get press mention</h4>
                  <p
                    className="text-700 fs-10 fs-sm-10"
                    style={{ fontSize: '16px' }}
                  >
                    Get daily personalized journalist inquiry leads. Partner
                    with brands for content collaboration.
                  </p>

                  <Link
                    className="font-weight-semi-bold fs-0 text-nowrap"
                    to="/register"
                  >
                    <FontAwesomeIcon
                      icon="video-camera"
                      transform="down-1.5"
                      className="mr-1"
                    />
                    Get press mention
                  </Link>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
        <div className=" d-md-block col-md-4 p-2">
          <div className="row">
            <div className="col">
              <Card className="mb-3 shadow-none border">
                <Background image={corner1} className="p-card bg-card" />
                <CardBody className="position-relative">
                  <h4 className="text-warning">Sell your business</h4>
                  <p
                    className="text-700 fs-10 fs-sm-10"
                    style={{ fontSize: '16px' }}
                  >
                    List and sell your business with expert support and guidance
                  </p>
                  <Link
                    className="font-weight-semi-bold fs-0 text-nowrap"
                    to="/register"
                  >
                    <FontAwesomeIcon
                      icon="video-camera"
                      transform="down-1.5"
                      className="ml-1"
                    />
                    List your business for sale
                  </Link>
                </CardBody>
              </Card>
              <Card className="mb-3 shadow-none border">
                <Background image={corner3} className="p-card bg-card" />
                <CardBody className="position-relative">
                  <h4 className="text-warning">Join helpful community</h4>
                  <p
                    className="text-700 fs-10 fs-sm-10"
                    style={{ fontSize: '16px' }}
                  >
                    Ask questions, gain insights, & learn how business owners
                    are thriving in the age of AI disruption.
                  </p>
                  <Link
                    className="font-weight-semi-bold fs-0 text-nowrap"
                    to="/register"
                  >
                    <FontAwesomeIcon
                      icon="video-camera"
                      transform="down-1.5"
                      className="ml-1"
                    />
                    Join our community
                  </Link>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
        <div className=" d-md-block col-md-4 p-2">
          <div className="row">
            <div className="col">
              <Card className="mb-3 shadow-none border">
                <Background image={corner1} className="p-card bg-card" />
                <CardBody className="position-relative">
                  <h4 className="text-warning">Hire affordable talent</h4>
                  <p
                    className="text-700 fs-10 fs-sm-10"
                    style={{ fontSize: '16px' }}
                  >
                    Access highly skilled tech talents for full-time, contract,
                    or part-time engagements.
                  </p>
                  <Link
                    className="font-weight-semi-bold fs-0 text-nowrap"
                    to="/register"
                  >
                    <FontAwesomeIcon
                      icon="video-camera"
                      transform="down-1.5"
                      className="ml-1"
                    />
                    Hire talent
                  </Link>
                </CardBody>
              </Card>
              <Card className="mb-3 shadow-none border">
                <Background image={corner3} className="p-card bg-card" />
                <CardBody className="position-relative">
                  <h4 className="text-warning">Find work</h4>
                  <p
                    className="text-700 fs-10 fs-sm-10"
                    style={{ fontSize: '16px' }}
                  >
                    Connect with founders, business buyers, and hiring managers
                    for potential job opportunities.
                  </p>
                  <Link
                    className="font-weight-semi-bold fs-0 text-nowrap"
                    to="/register"
                  >
                    <FontAwesomeIcon
                      icon="video-camera"
                      transform="down-1.5"
                      className="ml-1"
                    />
                    Find work
                  </Link>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};
export default HowItWorks;
