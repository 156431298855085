/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import React from 'react';
import Avatar from "components/common/Avatar";
import Flex from "components/common/Flex";
import { Link } from "react-router-dom";
import { abbreviateNumber, getFullName } from "utils/user_utils";
import { useTranslation } from 'react-i18next';
import { formatRelativeTime } from "utils/time";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import ContinousExpansionTextView from "./ContinuousExpansionTextView";
import useReplyBoxStore from "store/reply_box_store";
const { forwardRef, useRef } = require("react");
import ReplyComposeBox from './ReplyComposeBox';

const ReplyListTile = forwardRef((props, ref) => {

    const { t } = useTranslation();
    const commentId = props.commentId;
    const replyId = props._id;
    const activeReplyBoxes = useReplyBoxStore(state => state.activeReplyBoxes);
    const setActiveReplyBoxes = useReplyBoxStore(state => state.setActiveReplyBoxes);
    const replyBoxInputRef = useRef();

    return (
        <Flex className={'fs-10 gap-3'} justifyContent={'center'}>
            <Flex direction={'column'}>
                <Flex className={'gap-2'}>
                    <Avatar src={props?.author?.avatar} size="s" />
                    <Flex className={'flex-1 bg-200 gap-2 p-1 rounded-3'}>
                        <Link className="fw-semibold" to={`/${props?.author?.username}`}>{getFullName(props.author)}</Link>
                        <div className="flex-1">
                            <ContinousExpansionTextView maxLengthInView={100} text={`${props.text ?? ''}`} className="flex-1" />
                        </div>
                    </Flex>
                </Flex>
                <Flex alignItems={'center'} className={'ms-4 gap-2 mt-1'}>
                    <span style={{ fontSize: 11 }}>{formatRelativeTime(new Date(props.created_at ?? props.createdAt))}</span>
                    <span
                        onClick={() => {
                            if (props.compact) {
                                if (props.onReplyClickedInCompactMode) {
                                    props.onReplyClickedInCompactMode(replyId);
                                }
                                return;
                            }
                            if (activeReplyBoxes[replyId]) {
                                if (!replyBoxInputRef?.current?.value) {
                                    delete activeReplyBoxes[replyId];
                                }
                            } else {
                                activeReplyBoxes[replyId] = true;
                            }
                            setActiveReplyBoxes({ ...activeReplyBoxes });
                        }}
                        style={{ fontSize: 11 }} className='fs-12 text-gray cursor-pointer'>
                        {t('reply')}
                    </span>
                </Flex>
                {
                    activeReplyBoxes[replyId] &&
                    <ReplyComposeBox
                        boxId={replyId}
                        className={'ms-8'}
                        ref={replyBoxInputRef}
                        commentId={commentId}
                        onNewReplyUpserted={(reply) => {
                            if (props?.onNewReplyUpserted) {
                                props?.onNewReplyUpserted(reply);
                            }
                        }}
                    />
                }
            </Flex>
            <div className="flex-1"></div>
            <Flex className={'flex-1'} direction={'column'}>
                <Flex className={'cursor-pointer'} style={{ paddingRight: 20 }} alignItems={'center'} direction={'column'}>
                    <FontAwesomeIcon className={`text-${props.liked ? 'danger' : 'default'}`} icon={faHeart} />
                    {
                        (props?.likesCount ?? 0) > 0 &&
                        <span>{abbreviateNumber(props?.likesCount)}</span>
                    }
                </Flex>
            </Flex>
        </Flex>
    );
});

export default ReplyListTile;