import { DateTime } from 'luxon';

export function createDateWithTimeZone(date, timeZone, forced = false) {
  date = new Date(date);
  if (timeZone && forced) {
    return new Date(
      (typeof date === 'string' ? new Date(date) : date).toLocaleString(
        'en-US',
        { timeZone: timeZone }
      )
    );
  }
  return new Date(date);
}

export function formatPostDate(postDate) {
  const postDateTime = DateTime.fromJSDate(new Date(postDate));
  const now = DateTime.now();
  const diffInDays = Math.floor(
    now.diff(postDateTime.startOf('day'), 'days').days
  );
  if (diffInDays > 1) {
    return postDateTime.toLocaleString(DateTime.DATETIME_FULL, {
      locale: 'en-US'
    });
  } else if (diffInDays === 1) {
    return `Yesterday at ${postDateTime.toLocaleString(DateTime.TIME_SIMPLE)}`;
  } else {
    const diffInMinutes = Math.round(now.diff(postDateTime, 'minutes').minutes);
    if (diffInMinutes >= 60) {
      return `Today at ${postDateTime.toLocaleString(DateTime.TIME_SIMPLE)}`;
    } else if (diffInMinutes > 1) {
      return `${diffInMinutes} mins ago`;
    } else {
      return 'Just now';
    }
  }
}

export function formatRelativeTime(date) {
  const now = DateTime.now();
  const inputDate = DateTime.fromJSDate(date);
  return inputDate.toRelative({ base: now });
}
