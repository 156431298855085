/* eslint-disable react/prop-types */
import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery';
import FalconLightBox from 'components/common/FalconLightBox';
import InAppVideoPlayer from './InAppVideoPlayer';

const FeedGallery = props => {
  const { media = [], fromPostDetailsDialog } = props;

  const imagePlacementsMapping = fromPostDetailsDialog
    ? {
        1: [12],
        2: [6, 6],
        3: [6, 6, 12],
        4: [6, 6, 6, 6],
        5: [6, 6, 4, 4, 4],
        6: [4, 4, 4, 4, 4, 4],
        above: [4, 4, 4, 4, 4, 4]
      }
    : {
        1: [12],
        2: [6, 6],
        3: [6, 6, 12],
        // 4: [6, 6, 6, 6],
        // 5: [6, 6, 4, 4, 4],
        // 6: [4, 4, 4, 4, 4, 4],
        above: [4, 4, 4, 4, 4, 4]
      };

  return (
    <div>
      {media.length === 1 && (
        <div>
          {media[0].type === 'video' ? (
            <InAppVideoPlayer borderRadius={10} media={media[0]} />
          ) : (
            <FalconLightBox image={media[0]}>
              <Image src={media[0].url} fluid rounded />
            </FalconLightBox>
          )}
        </div>
      )}
      {media.length > 1 && (
        <FalconLightBoxGallery images={media.map(item => item.url)}>
          {setImgIndex => (
            <Row className="g-2">
              {media.map((file, index) => {
                let mapping =
                  imagePlacementsMapping[media.length] ??
                  imagePlacementsMapping['above'];
                return file.type === 'video' ? (
                  <Col xs={12}>
                    <InAppVideoPlayer
                      borderRadius={10}
                      media={file}
                      controls={true}
                      className="react-player"
                    />
                  </Col>
                ) : (
                  <Col xs={mapping[index]}>
                    <Image
                      src={file.url}
                      fluid
                      rounded
                      style={{
                        cursor: 'pointer'
                      }}
                      onClick={() => setImgIndex(index)}
                    />
                  </Col>
                );
              })}
            </Row>
          )}
        </FalconLightBoxGallery>
      )}
    </div>
  );
};

export default FeedGallery;
