/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import ReactPlayer from 'react-player';
import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';

export default function InAppVideoPlayer({
  media,
  borderRadius = 0,
  style = {}
}) {
  const [error, setError] = useState();

  if (media.file) {
    return (
      <ReactPlayer url={media.preview} controls muted style={{ ...style }} />
    );
  }
  return (
    <>
      {error && (
        <Flex alignItems={'center'} justifyContent={'center'}>
          <Spinner />
        </Flex>
      )}
      {!error && media.url.includes('cloudflarestream') ? (
        <div
          style={{
            position: 'relative',
            paddingTop: '56.25%'
          }}
        >
          <iframe
            src={`${media.url}?poster=${media.thumbnail}`}
            style={{
              border: 'none',
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              borderRadius
            }}
            onError={() => setError(true)}
            onLoad={() => setError(false)}
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowFullScreen
          ></iframe>
        </div>
      ) : (
        <ReactPlayer url={url} controls muted style={{ ...style }} />
      )}
    </>
  );
}
