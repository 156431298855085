import React, { useEffect, useState } from 'react';
import CreatePost from './CreatePost';
import FeedCard from './FeedCard';
import classNames from 'classnames';
import usePostsStore from 'store/posts_store';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';

const FeedContent = () => {
  const posts = usePostsStore(state => state.posts);
  const setPosts = usePostsStore(state => state.setPosts);
  const [fetching, setFetching] = useState(false);

  const fetchPostsOfInterest = () => {
    setFetching(true);
    APIService.fetchPostsOfInterest((response, error) => {
      setFetching(false);
      if (error) {
        toast.error(error, { theme: 'colored' });
        return;
      }
      let { data } = response;
      data.forEach(incomingPost => {
        const existingIndex = posts.findIndex(
          post => post._id === incomingPost._id
        );
        if (existingIndex !== -1) {
          posts[existingIndex] = incomingPost;
        } else {
          posts.unshift(incomingPost);
        }
      });
      posts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setPosts([...posts]);
    });
  };

  useEffect(() => {
    fetchPostsOfInterest();
  }, []);

  return (
    <>
      <CreatePost className="mb-3" />
      {fetching && (
        <Flex alignItems={'center'} justifyContent={'center'}>
          <Spinner animation="border" size="small" />
        </Flex>
      )}
      {posts.map((post, index) => (
        <FeedCard
          key={post._id}
          feed={post}
          className={classNames({
            'mb-3': index + 1 !== posts.length,
            'mb-3 mb-lg-0': index + 1 === posts.length
          })}
        />
      ))}
    </>
  );
};

export default FeedContent;
