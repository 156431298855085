/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import image from 'assets/img/icons/spot-illustrations/image.svg';
import writeArticle from 'assets/img/icons/article.png';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import TextareaAutoSize from 'react-textarea-autosize';
import { useAppContext } from 'providers/AppProvider';
import { useTranslation } from 'react-i18next';
import APIService from 'http/api_service';
import usePublishablePostStore from 'store/publishable_post_store';
import TopicsComponent from './TopicsComponent';
import { toast } from 'react-toastify';
import usePostsStore from 'store/posts_store';
import MediaPreview from 'components/common/MediaPreview';
import StringUtils from 'utils/string';

const CreatePostForm = ({ postingFeedBack, handlePostingFeedBack }) => {
  const { t } = useTranslation();
  const {
    config: { isDark }
  } = useAppContext();
  const [posting, setPosting] = useState(false);
  const post = usePublishablePostStore(state => state.post);
  const setPost = usePublishablePostStore(state => state.setPost);
  const posts = usePostsStore(state => state.posts);
  const setPosts = usePostsStore(state => state.setPosts);
  const mediaInputRef = useRef(null);
  const maxFilesCount = 10;

  const handleSubmit = async e => {
    e.preventDefault();
    setPosting(true);
    handlePostingFeedBack({ posting: true });
    const media = post.media ?? [];
    if (media.length > 0) {
      for (let mediaEntry of media) {
        if (mediaEntry.file) {
          try {
            let response = await APIService.uploadFile(mediaEntry.file, {
              onUploadProgress: progress => {
                if (progress >= 100) {
                  handlePostingFeedBack({ posting: true });
                } else {
                  handlePostingFeedBack({
                    posting: true,
                    progress,
                    label: `${t('uploading')} ${
                      mediaEntry.file?.name ?? 'file(s)'
                    }`
                  });
                }
              }
            });
            delete mediaEntry.file;
            if (mediaEntry.type === 'video') {
              Object.assign(mediaEntry, response);
            } else {
              const { success, result } = response;
              if (success) {
                let uploadedPhotoUrl = (result.variants ?? [])[0];
                if (uploadedPhotoUrl) {
                  mediaEntry.url = uploadedPhotoUrl;
                  mediaEntry._id = result.id;
                }
              }
            }
          } catch (e) {
            setPosting(false);
            handlePostingFeedBack(undefined);
            console.error(e);
            toast.error(t('file_upload_error'), { theme: 'colored' });
            return;
          }
        }
      }
    }
    setPosting(true);
    handlePostingFeedBack({ posting: true });
    setPost({ ...post, media });
    APIService.upsertPost(post, (response, error) => {
      setPosting(false);
      handlePostingFeedBack(undefined);
      if (error) {
        toast.error(error, { theme: 'colored' });
        return;
      }
      setPost({});
      let { data } = response;
      let postsCopy = [...posts];
      let postIndex = postsCopy.findIndex(post => post._id === data._id);
      if (postIndex !== -1) {
        postsCopy[postIndex] = data;
      } else {
        postsCopy.unshift(data);
      }
      setPosts([...postsCopy]);
    });
  };

  const prePostTooltip = () => {
    if (!post.text && !post.media) {
      return t('add_some_text_or_media');
    }
    if ((post.topics ?? []).length < 1) {
      return t('pick_a_topic_before_posting');
    }
    return null;
  };

  const handleFileInputChange = event => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      let existingMedia = post.media ?? [];
      existingMedia = [
        ...existingMedia,
        ...Array.from(selectedFiles).map(file => {
          return {
            _id: StringUtils.generateRandomString(360),
            file,
            type: StringUtils.substringBeforeLast(file.type, '/')
          };
        })
      ];
      if (existingMedia.length > maxFilesCount) {
        existingMedia.shift();
        toast.info(
          t('some_files_removed_due_to_capped_limit', { n: maxFilesCount }),
          { theme: 'colored' }
        );
      }
      setPost({ ...post, media: existingMedia });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <TextareaAutoSize
        id="compose-box"
        minRows={4}
        as="textarea"
        value={post.text ?? ''}
        placeholder={t('what_is_on_your_mind')}
        className="form-control outline-none resize-none rounded-0 border-0 emojiarea-editor"
        style={{
          width: '100%',
          outline: 'none',
          whiteSpace: 'pre-wrap',
          display: 'inherit',
          background: isDark ? '#0b1727' : '#fff',
          color: 'inherit',
          fontSize: 'inherit'
        }}
        maxRows={18}
        onChange={e => {
          setPost({ ...post, text: e.target.value });
        }}
      />
      <TopicsComponent />
      <MediaPreview hideRemoveIcons={postingFeedBack} />
      <Row className="g-0 mt-3 px-x1 pb-3 justify-content-between">
        <Col>
          <PostButton
            onClick={() => mediaInputRef.current.click()}
            image={image}
            title={t('add_media')}
          />
          <input
            ref={mediaInputRef}
            type="file"
            multiple
            accept="image/*,video/*"
            style={{ display: 'none' }}
            onChange={handleFileInputChange}
          />
          <PostButton image={writeArticle} title={t('write_article')} />
        </Col>
        <Col xs="auto">
          <OverlayTrigger
            overlay={
              prePostTooltip() != null ? (
                <Tooltip
                  style={{ position: 'fixed' }}
                  id="pre-publish-post-trigger"
                >
                  {prePostTooltip()}
                </Tooltip>
              ) : (
                <div></div>
              )
            }
          >
            <span>
              <Button
                disabled={
                  posting ||
                  (!post.text && !post.media) ||
                  (post.topics ?? []).length < 1
                }
                size="sm"
                className="px-4 px-sm-5"
                type="submit"
              >
                {post._id ? t('update') : t('post')}
              </Button>
            </span>
          </OverlayTrigger>
        </Col>
      </Row>
    </Form>
  );
};

const PostButton = ({ image, title, ...others }) => (
  <Button
    variant="tertiary"
    size="sm"
    className="rounded-pill shadow-none d-inline-flex align-items-center fs-10 mb-0 me-1"
    {...others}
  >
    <img src={image} alt="" width={17} />
    <span className="ms-2 d-none d-md-inline-block">{title}</span>
  </Button>
);

PostButton.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default CreatePostForm;
