/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Divider from 'components/common/Divider';
import SocialAuthButtons from './SocialAuthButtons';
import { useTranslation } from 'react-i18next';
import AuthTermsAndPolicies from './AuthTermsAndPolicies';
import SubmitButton from 'components/common/SubmitButton';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import useTempStore from 'store/tempStore';

const RegistrationForm = ({
  hasLabel,
  nextStep = 0,
  handleNextStep,
  loosedSocials
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: ''
  });
  const [processing, setProcessing] = useState(false);
  const setAuthenticatingEmail = useTempStore(
    state => state.setAuthenticatingEmail
  );

  const handleSubmit = e => {
    e.preventDefault();
    setProcessing(true);
    setAuthenticatingEmail(formData.email);
    APIService.authenticateWithMagicLink(formData.email, (response, error) => {
      setProcessing(false);
      if (error) {
        toast.error(error);
        return;
      }
      handleNextStep(nextStep + 1, formData);
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? t('enter_email_address') : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <SubmitButton
          className="w-100"
          type="submit"
          disabled={!formData.email || processing}
          processing={processing}
          label={t('register')}
        />
      </Form.Group>
      <Divider>{`${t('or')}`}</Divider>
      <div className="my-4">
        <SocialAuthButtons
          intent={'registeration'}
          loosedSocials={loosedSocials}
        />
      </div>
      <AuthTermsAndPolicies />
    </Form>
  );
};

RegistrationForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default RegistrationForm;
