/* eslint-disable react/prop-types */
import React from 'react';
import { Card } from 'react-bootstrap';
import FeedEvent from './FeedEvent';
import classNames from 'classnames';
import FeedUrl from './FeedUrl';
import FeedGallery from 'components/app/social/feed/FeedGallery';
import ContinousExpansionTextView from './ContinuousExpansionTextView';

const FeedCardContent = ({ text, media = [], feedEvent, url }) => {
  return (
    <Card.Body className={classNames({ 'p-0': false })}>
      {text && <ContinousExpansionTextView text={text} />}
      {media.length > 0 && <FeedGallery media={media} />}
      {feedEvent && <FeedEvent {...feedEvent} />}
      {url && <FeedUrl {...url} />}
    </Card.Body>
  );
};

export default FeedCardContent;
