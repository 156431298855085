/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
  Button,
  InputGroup
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PeopleCardGrid from './PeopleCardGrid';
import PeopleList from './PeopleList';
import { useTranslation } from 'react-i18next';

import { useProductContext } from 'providers/ProductProvider';
import CartModal from './people-details-dialog/UserProfileModal';
import usePagination from 'hooks/usePagination';
import Flex from 'components/common/Flex';
import { Helmet } from 'react-helmet';

const Connections = () => {
  const {
    productsState: { products },
    productsDispatch
  } = useProductContext();

  const [sortBy, setSortBy] = useState('id');
  const [isAsc, setIsAsc] = useState(true);
  const [productPerPage, setProductPerPage] = useState(6);
  const { t } = useTranslation();

  const {
    paginationState: {
      data: paginatedProducts,
      totalItems,
      itemsPerPage,
      currentPage,
      canNextPage,
      canPreviousPage,
      paginationArray,
      from,
      to
    },
    nextPage,
    prevPage,
    goToPage,
    setItemsPerPage
  } = usePagination(products, productPerPage);

  useEffect(() => {
    productsDispatch({
      type: 'SORT_PRODUCT',
      payload: {
        sortBy,
        order: isAsc ? 'asc' : 'desc'
      }
    });
  }, [sortBy, isAsc]);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   isList || isGrid || navigate('/errors/404');
  // }, []);

  return (
    <>
      <Helmet>
        <title>{`${t('connection_page_title')} - ${t('app_name')}`}</title>
        <meta charSet="utf-8" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@reachableonline " />
        <meta name="twitter:creator" content="@reachableonline " />
        <meta property="og:description" content={t('app_name')} />
        <meta
          content="https://twitter.com/reachableonline"
          property="og:see_also"
        />
        <meta name="twitter:title" content={t('app_name')} />
        <meta name="twitter:description" content="" />
        {/* <meta name="twitter:image" content="https://promoteapp.s3.amazonaws.com/promote-twitter-card.jpeg" /> */}
        <meta name="twitter:image:width" content="1001 " />
        <meta name="twitter:image:height" content="459 " />
        <link href="https://reachable.chat/" rel="canonical " />
        <link href="https://reachable.chat/" rel="home " />
      </Helmet>
      <Card className="mb-3">
        <Card.Body>
          <Row className="flex-between-center g-2">
            <Col
              sm="auto"
              as={Flex}
              alignItems="center"
              className="mb-2 mb-sm-0"
            >
              <h6 className="mb-0">
                Make honest connections online and in real life
              </h6>
            </Col>
            <Col sm="auto">
              <Row className="gx-2 align-items-center">
                <Col xs="auto">
                  <Form as={Row} className="gx-2">
                    <Col xs="auto">
                      <small>Looking for</small>
                    </Col>
                    <Col xs="auto">
                      <InputGroup size="sm">
                        <Form.Select
                          className="pe-5"
                          defaultValue="price"
                          onChange={({ target }) => setSortBy(target.value)}
                        >
                          <option value="price">Friendship</option>
                          <option value="rating">Love</option>
                          <option value="review">Relationship</option>
                          <option value="review">Business partner</option>
                          <option value="review">Newsletter sponsor</option>
                        </Form.Select>
                        <InputGroup.Text
                          as={Button}
                          variant="link"
                          className="border border-300 text-700"
                          onClick={() => setIsAsc(!isAsc)}
                        >
                          <FontAwesomeIcon
                            icon={isAsc ? 'sort-amount-up' : 'sort-amount-down'}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </Col>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body className="text-center">
          <h5 className="mb-2">You’ve hit the end of the line — for today!</h5>
          <p style={{ maxWidth: '520px', margin: '0 auto' }}>
            Want to see more amazing people? Upgrade to Reachable Premium, or
            wait until tomorrow for more potential connections.
          </p>
          <Button variant="primary" onClick="" className="mt-3" trigger="focus">
            Upgrade to Premium for more
          </Button>
        </Card.Body>

        <Card.Body className="pb-0">
          Connect with people looking for date
          <br />
          <br />
          <Row className="g-3">
            {paginatedProducts.map((product, index) => (
              // <PeopleList product={product} key={product.id} index={index} />
              <PeopleCardGrid
                product={product}
                key={product.id}
                md={6}
                lg={4}
                index={index}
              />
            ))}
          </Row>
        </Card.Body>
        <Card.Footer className={classNames('d-flex justify-content-center')}>
          <div>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip style={{ position: 'fixed' }}>Prev</Tooltip>}
            >
              <Button
                variant="falcon-danger"
                size="sm"
                disabled={!canPreviousPage}
                onClick={prevPage}
                className="me-2"
                trigger="focus"
              >
                <FontAwesomeIcon icon="chevron-left" />
              </Button>
            </OverlayTrigger>
          </div>

          <ul className="pagination mb-0">
            {paginationArray.map(page => (
              <li
                key={page}
                className={classNames({ active: currentPage === page })}
              >
                <Button
                  size="sm"
                  variant="falcon-success"
                  className="page me-2"
                  onClick={() => goToPage(page)}
                >
                  {page}
                </Button>
              </li>
            ))}
          </ul>
          <div>
            <OverlayTrigger
              trigger="click"
              placement="top"
              overlay={
                <Tooltip style={{ position: 'fixed' }} id="button-tooltip-2">
                  Next
                </Tooltip>
              }
            >
              <Button
                variant="falcon-default"
                size="sm"
                disabled={!canNextPage}
                onClick={nextPage}
                trigger="focus"
              >
                <FontAwesomeIcon icon="chevron-right" />
              </Button>
            </OverlayTrigger>
          </div>
        </Card.Footer>
      </Card>
      <CartModal />
    </>
  );
};

export default Connections;
