import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Container, Navbar } from 'react-bootstrap';
import {
  lightTransparency,
  darkTransparency
} from 'helpers/handleNavbarTransparency';
// import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus';
import LandingRightSideNavItem from './LandingRightSideNavItem';
import { topNavbarBreakpoint } from 'config';
import { useAppContext } from 'providers/AppProvider';
// import ThemeControlDropdown from 'components/navbar/top/ThemeControlDropdown';
import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import logo from 'assets/img/logos/logo.png';

const LandingNavbar = () => {
  const {
    config: { isDark }
  } = useAppContext();
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const handleTransparency = isDark ? darkTransparency : lightTransparency;
    window.addEventListener('scroll', handleTransparency);
    return () => window.removeEventListener('scroll', handleTransparency);
  }, [isDark]); // Add isDark as a dependency here

  return (
    <Navbar
      variant={isDark ? 'light' : 'dark'}
      fixed="top"
      expand={topNavbarBreakpoint}
      className={classNames('navbar-standard navbar-theme', {
        'bg-100': !navbarCollapsed && isDark,
        'bg-dark': !navbarCollapsed && !isDark
      })}
    >
      <Container>
        <Navbar.Brand className="text-secondary" as={Link} to="/">
          <img className="me-0 mb-1" src={logo} alt="Logo" width={31} />{' '}
          {t('app_name')}
        </Navbar.Brand>
        <Flex alignItems="center" className="gap-2">
          {/* <ThemeControlDropdown dropdownClassName="d-lg-none" /> */}
          <Navbar.Toggle onClick={() => setNavbarCollapsed(!navbarCollapsed)} />
        </Flex>
        <Navbar.Collapse className="scrollbar">
          <LandingRightSideNavItem />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default LandingNavbar;
