/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useUserStore from 'store/userStore';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { abbreviateNumber } from 'utils/user_utils';

const LCSText = ({ count, text, alternateText }) => (
  <span className="text-700 me-1 cursor-pointer">
    {alternateText ?? (
      <>
        {count} {text}
      </>
    )}
  </span>
);

const FeedReactions = ({
  likesCount = 0,
  commentsCount = 0,
  sharesCount = 0,
  commented,
  likes,
  liked
}) => {
  const { t } = useTranslation();
  const authenticatedUser = useUserStore(state => state.authenticatedUser);

  const getLikedText = () => {
    if (likesCount === 1) {
      return liked ? `${t('you')} ${t('like').toLowerCase()} ${t('this')}` : `${likesCount} ${t('likes', { count: likesCount, n: abbreviateNumber(likesCount) })}`;
    } else {
      return liked ? `${t('youAndNOthers', { count: likesCount - 1, n: abbreviateNumber(likesCount - 1) })} ${t('like').toLowerCase()} ${t('this')}` : `${t('likes', { count: likesCount, n: abbreviateNumber(likesCount) })}`;
    }
  }

  const getNamesOfLikers = () => {
    return likes.map(like =>
      like?.user?._id === authenticatedUser?._id
        ? t('you')
        : `${like?.user?.first_name} ${like?.user?.last_name}`
    );
  }

  return (
    <div className="border-bottom border-200 fs-10 py-2">
      {likesCount > 0 && (
        <OverlayTrigger
          overlay={
            <Tooltip
              style={{ position: 'fixed' }}
              id="overlay-trigger-for-likers"
            >
              <Flex
                direction={'column'}
                className={'gap-1'}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
              >
                {getNamesOfLikers().map(name => {
                  return <span key={name}>{name}</span>;
                })}
              </Flex>
            </Tooltip>
          }
        >
          <span>
            <>
              <FontAwesomeIcon
                icon={faHeart}
                className={`text-${liked ? 'danger' : 'default'} me-1`}
              />
              <LCSText
                alternateText={getLikedText()}
                text="like"
              />
            </>
          </span>
        </OverlayTrigger>
      )}
      {commentsCount > 0 && (
        <>
          {likesCount > 0 && '• '}
          <LCSText
            count={abbreviateNumber(commentsCount)}
            text={t('comments', { count: commentsCount })}
          />{' '}
        </>
      )}
      {sharesCount > 0 && (
        <>
          {(likesCount > 0 || commentsCount > 0) && '• '}
          <LCSText count={sharesCount} text="share" />{' '}
        </>
      )}
    </div>
  );
};

export default FeedReactions;
